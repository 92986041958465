<template>
    <div v-if="isOverviewReady" ref="product-detail" class="product-detail">
        <top-splash-component :top-splash-data="topSplashData" @promo-click="showTab('promotions', false, true)"></top-splash-component>
        <div v-if="isReady">
            <div class="container mt-4">
                <div class="product-detail-overview mb-6">
                    <div>
                        <h1 v-html="sailing.cruiseName"></h1>
                        <div v-if="sailing.isCruiseTour" class="mt-1"><b>CRUISETOUR</b></div>
                        <div v-if="sailing.sailings.length > 1" class="mt-2">
                            Travel Dates<br />
                            <template v-if="isEmbeddedMode()">
                                Ask your advisor about available travel dates
                            </template>
                            <label v-else class="select--styled">
                                <select id="itineraryNav" class="w-100" aria-label="Select Travel Dates" @change="goToSailing()">
                                    <option v-for="(item, index) in sailing.sailings" :key="index" :value="item.id" :selected="item.id === sailing.id" :data-url="item.url" v-html="item.travelDates"></option>
                                </select>
                            </label>
                        </div>
                        <div class="mt-3 -description">
                            <b v-if="sailing.sailings.length <= 1">{{ sailing.travelDates }}<br /></b>
                            <b v-html="sailing.cruisePorts"></b><br />
                            <b v-if="isEmbeddedMode()" class="weglot-exclude" v-html="sailing.companyName"></b>
                            <a v-else class="weglot-exclude" :href="sailing.brandUrl"><b v-html="sailing.companyName"></b></a>
                            <br />
                            Ship: <b v-if="isEmbeddedMode()" class="weglot-exclude" v-html="sailing.shipName"></b>
                            <b v-else class="weglot-exclude"><a :href="sailing.shipUrl" v-html="sailing.shipName"></a></b>
                        </div>

                        <div v-if="isNetworkUser() && sailing.cruiseDepartureCode" class="mt-2">Sailing ID: {{ sailing.cruiseDepartureCode }}</div>

                        <h2 class="text--serif mt-3">At a Glance</h2>

                        <img :src="sailing.cruiseMapUrl" width="500" :alt="'Cruise Itinerary Map for ' + sailing.cruiseName" :title="'Cruise Itinerary Map for ' + sailing.cruiseName" class="mt-2" loading="lazy" />

                        <div v-if="sailing.whatIsIncluded" class="mt-2">
                            <div class="mb-1"><b>Included</b></div>
                            <div v-html="sailing.whatIsIncluded"></div>
                        </div>

                        <div v-if="sailing.whatIsNotIncluded" class="mt-2">
                            <div class="mb-1"><b>Not Included</b></div>
                            <div v-html="sailing.whatIsNotIncluded"></div>
                        </div>
                    </div>
                    <div class="-gallery mt-5 mt-md-0 mb-6">
                        <image-gallery-component v-if="sailing.galleryImages && sailing.galleryImages.length"
                                                 :gallery-data="sailing.galleryImages"
                                                 :product-id="sailing.id.toString()"
                                                 :product-name="sailing.cruiseName"
                                                 :product-type="ProductType.CRUISES"></image-gallery-component>
                        <recommended-advisors-component v-if="!suppressRecommendedAdvisors" :advisor-query="recommendedAdvisorQuery" :product-type="ProductType.CRUISES" :set-recommended-advisor-ids="setRecommendedAdvisorIds"></recommended-advisors-component>
                        <a v-if="isNetworkUser() && !isEmbeddedMode()" id="b2blink-legacy-link-label" class="btn btn-primary-emphasis btn-sm d-block mt-3" :href="legacyLink" @click="setB2BDesktopCookie()" v-html="legacyLinkLabel"></a>
                        <track-sailing-component :sailing-id="productId"></track-sailing-component>
                        <button class="wl-heartable -save-this mt-3" data-wl-type="cruise" :data-wl-id="heartableUrl" :data-wl-title="cruiseTitle"></button>
                    </div>
                </div>
            </div>
            <div id="detail-tabs" class="jump-target"></div>
            <div class="container d-none d-md-block">
                <ul ref="tab-nav-container" class="tab-nav-container">
                    <li id="tab-itinerary"><button @click="showTab('itinerary')">Itinerary</button></li>
                    <li v-if="hasBenefits" id="tab-benefits"><button @click="showTab('benefits')">Virtuoso Benefits</button></li>
                    <li v-if="sailing.promotions.length" id="tab-promotions"><button @click="showTab('promotions')">Promotions</button></li>
                    <li v-if="sailing.addOnDayTours.length || sailing.postPackages.length || sailing.prePackages.length" id="tab-add-ons"><button @click="showTab('add-ons')">Add-ons</button></li>
                </ul>
            </div>
            <div class="slab -tab-slab">
                <div class="container">
                    <ul class="tab-content">
                        <li id="tc-itinerary">
                            <button class="tab-nav" @click="showTab('itinerary')">Itinerary</button>
                            <div class="-container">
                                <h4 class="tab-title">Itinerary</h4>
                                <div v-if="sailing.isCruiseTour" class="my-1 cruisetour-key"><span class="text-nowrap me-3"><i class="icon-shuttle"></i> Tour Segment</span> <span class="text-nowrap"><i class="icon-Cruises"></i> Cruise Segment</span></div>
                                <product-itinerary-component :itinerary="sailing.itinerary" :product-type="ProductType.CRUISES" :is-cruise-tour="sailing.isCruiseTour"></product-itinerary-component>
                            </div>
                        </li>
                        <li v-if="hasBenefits" id="tc-benefits">
                            <button class="tab-nav" @click="showTab('benefits')">Virtuoso Benefits</button>
                            <div class="-container">
                                <h4 class="tab-title">Virtuoso Benefits</h4>
                                <div v-if="sailing.benefitsHostedGroups.length > 0" class="mb-4 mt-2">
                                    <h2 class="text--serif mt-2">Hosted Benefits</h2>
                                    <div v-for="(hbg, index) in sailing.benefitsHostedGroups" :key="index" class="benefits-box mt-2">
                                        <div class="-title-row">
                                            <div class="-dates" v-html="hbg.dates"></div>
                                            <div class="-hosts" v-html="hbg.hosts"></div>
                                        </div>
                                        <div v-if="hbg.hostedBenefits.length > 0" class="benefits-container">
                                            <div class="text--small">Guests may select one of the following benefits. Please consult your Virtuoso travel advisor for restrictions and fees that apply to 3rd and 4th passengers in the cabin.</div>
                                            <div :class="[hbg.hostedBenefits.length > 1 ? 'carousel hosted-benefits-carousel' : '']">
                                                <cruise-benefit-component v-for="(ben, benIndex) in hbg.hostedBenefits"
                                                                          :key="benIndex"
                                                                          :benefit="ben"
                                                                          :is-hosted-benefit="true"
                                                                          :is-carousel="true"
                                                                          :hosted-benefit-index="benIndex"
                                                                          :total-hosted-benefits="hbg.hostedBenefits.length"></cruise-benefit-component>
                                            </div>
                                        </div>
                                        <div v-if="hbg.forAllBenefits.length > 0" class="container mt-2">
                                            <div><b>Guests may also enjoy these benefits.</b> Restrictions may apply.</div>
                                            <cruise-benefit-component v-for="(ben, benIndex) in hbg.forAllBenefits" :key="benIndex" :benefit="ben" :is-hosted-benefit="false"></cruise-benefit-component>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="sailing.benefitsExclusive.length > 0" class="mt-2 mb-4">
                                    <h2 class="text--serif">Exclusive Benefits</h2>
                                    <div class="benefits-box mt-2">
                                        <div class="benefits-container">
                                            <div>Guests may also be entitled to the following complimentary benefits. Please consult your Virtuoso travel advisor for restrictions that may apply.</div>
                                            <cruise-benefit-component v-for="(ben, benIndex) in sailing.benefitsExclusive" :key="benIndex" :benefit="ben" :is-hosted-benefit="false"></cruise-benefit-component>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="sailing.benefitsHQGroups.length > 0" class="mt-2">
                                    <h2 class="text--serif">HQ Group Benefits</h2>
                                    <div class="benefits-box mt-2">
                                        <div class="benefits-container">
                                            <div>Benefits may be recalled by the cruise line at any time. Please contact the cruise line to verify availability before offering them to your clients. Combinability restrictions may also apply, please see the Booking Instructions for information.</div>
                                            <div v-for="(hqGroup, index) in sailing.benefitsHQGroups" :key="index">
                                                <template v-if="hqGroup.benefits.length > 0">
                                                    <div v-if="hqGroup.visibilityCountries && isVStaff()" class="text-danger mt-2">Visible Only to {{ hqGroup.visibilityCountries }}</div>
                                                    <cruise-benefit-component v-for="(ben, benIndex) in hqGroup.benefits" :key="benIndex" :benefit="ben" :is-hosted-benefit="false"></cruise-benefit-component>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="sailing.promotions.length" id="tc-promotions">
                            <button class="tab-nav" @click="showTab('promotions')">Promotions</button>
                            <div class="-container">
                                <h4 class="tab-title">Promotions</h4>
                                <promotions-component :product-type="ProductType.CRUISES" :promotions="sailing.promotions"></promotions-component>
                            </div>
                        </li>
                        <li v-if="sailing.addOnDayTours.length || sailing.postPackages.length || sailing.prePackages.length" id="tc-add-ons">
                            <button class="tab-nav" @click="showTab('add-ons')">Add-ons</button>
                            <div class="-container">
                                <h4 class="tab-title">Add-ons</h4>

                                <div v-if="sailing.prePackages.length > 0" class="mb-4">
                                    <h2 class="text--serif">Pre-Packages</h2>
                                    <div class="add-ons-box mt-2">
                                        <div class="-title-row">
                                            <div class="-location" v-html="sailing.prePackages[0].location"></div>
                                            <div class="-toggle"><button aria-label="Toggle Pre-Packages" @click.prevent.stop="toggleAddOn('prepackages')"><i id="add-on-toggle-prepackages" class="icon-plus-circle-ut"></i></button></div>
                                        </div>
                                        <div id="add-on-container-prepackages" class="add-on-container">
                                            <add-on-tours-component v-for="(pkg, pkgIndex) in sailing.prePackages" :key="pkgIndex" :add-on="pkg"></add-on-tours-component>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="sailing.addOnDayTours.length > 0" class="mb-4">
                                    <h2 class="text--serif">Shore Excursions</h2>
                                    <div v-for="(segment, segmentIndex) in sailing.addOnDayTours" :key="segmentIndex" class="add-ons-box mt-2">
                                        <div class="-title-row">
                                            <div class="-location">{{ segment.addOnDate }} <span class="d-none d-md-inline">&mdash;</span><span class="d-md-none"><br /></span> {{ segment.addOnLocation }}</div>
                                            <div class="-toggle"><button aria-label="Toggle Shore Excursions" @click.prevent.stop="toggleAddOn('shore-excursion-' + segmentIndex)"><i :id="'add-on-toggle-shore-excursion-' + segmentIndex" class="icon-plus-circle-ut"></i></button></div>
                                        </div>
                                        <div :id="'add-on-container-shore-excursion-' + segmentIndex" class="add-on-container">
                                            <add-on-tours-component v-for="(pkg, pkgIndex) in segment.addOns" :key="pkgIndex" :add-on="pkg"></add-on-tours-component>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="sailing.postPackages.length > 0">
                                    <h2 class="text--serif">Post-Packages</h2>
                                    <div class="add-ons-box mt-2">
                                        <div class="-title-row">
                                            <div class="-location" v-html="sailing.postPackages[0].location"></div>
                                            <div class="-toggle"><button aria-label="Toggle Post-Packages" @click.prevent.stop="toggleAddOn('postpackages')"><i id="add-on-toggle-postpackages" class="icon-plus-circle-ut"></i></button></div>
                                        </div>
                                        <div id="add-on-container-postpackages" class="add-on-container">
                                            <add-on-tours-component v-for="(pkg, pkgIndex) in sailing.postPackages" :key="pkgIndex" :add-on="pkg"></add-on-tours-component>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div v-else class="v-loading -no-overlay my-3"></div>
    </div>
    <LogoSplash v-else />
</template>


<script setup lang="ts">
    import AddOnToursComponent from "components/products/details/add-on-tours.vue";
    import CruiseBenefitComponent from "components/products/details/cruise-benefit.vue";
    import ProductItineraryComponent from "components/products/details/product-itinerary.vue";
    import PromotionsComponent from "components/products/details/promotions.vue";
    import TrackSailingComponent from "components/products/details/track-sailing.vue";
    import RecommendedAdvisorsComponent from "components/advisor/recommended-advisors.vue";
    import ImageGalleryComponent from "components/shared/image-gallery.vue";
    import LogoSplash from "components/shared/logo-splash.vue";
    import TopSplashComponent from "components/shared/top-splash.vue";
    import { AddOnPackage, ProductDetailsCruiseResponse } from "interfaces/responses/product-detail-responses";
    import { RecommendedAdvisorQuery } from "interfaces/advisor";
    import { CruiseBenefit } from "interfaces/cruise";
    import { ProductType } from "interfaces/enums";
    import { GalleryItem } from "interfaces/image";
    import { ProductDetailsSailing, ProductTopSplash, Promotion } from "interfaces/product";
    import { AddOnDayTours, SortedBenefits, TourEvent } from "interfaces/tour";
    import { getProductDetails } from "services/api/products";
    import { isNetworkUser, isSupplier, isVStaff } from "services/auth/user-info";
    import { sanitizeUserGeneratedContent, toggleSlideWithFade } from "services/helpers/html";
    import { generateMediaServerImageUrl, hydrateImageGallery } from "services/helpers/images";
    import { toastError } from "services/helpers/toasts";
    import { isEmbeddedMode, isMobileScreenWidth } from "services/layout/environment";
    import { setB2BDesktopCookie } from "services/layout/metadata";
    import { translateToConsumerUrl } from "services/transformers/products";
    import { trackEvent } from "services/analytics";
    import { CarouselConfig, createCarousel } from "services/carousel";
    import { enableHearts } from "services/wanderlist";
    import { cobrandLink, getPlural, parseURLParameters, slugify } from "virtuoso-shared-web-ui";
    import { nextTick, ref, useTemplateRef } from "vue";

    let hasMultipleHostedBenefits = false;
    let isBenefitsCarouselInitialized = false;
    let topPromoName = ""; // Referenced by GA

    const props = defineProps({
        productId: {
            type: Number,
            default: undefined
        },
        suppressRecommendedAdvisors: {
            type: Boolean,
            default: false
        }
    });

    const cruiseTitle = ref("");
    const heartableUrl = ref("");
    const hasBenefits = ref(false);
    const isOverviewReady = ref(false);
    const isReady = ref(false);
    const legacyLink = cobrandLink(`/cruises/sailings/${props.productId}`);
    const legacyLinkLabel = (isSupplier()) ? "For Partners: Update Your Profile" : "For Advisors: Advanced Results";
    const productDetailRef = useTemplateRef("product-detail");
    const qsParams = parseURLParameters();
    const recommendedAdvisorQuery = ref<RecommendedAdvisorQuery>({} as RecommendedAdvisorQuery);
    const sailing = ref<ProductDetailsSailing>({} as ProductDetailsSailing);
    const tabNavContainerRef = useTemplateRef("tab-nav-container");
    const topSplashData = ref<ProductTopSplash>({} as ProductTopSplash); 

    function autoExpandAndToggle(item: string): void {
        toggleAddOn(item);
    }

    function goToSailing(): void {
        const elSelect = document.getElementById("itineraryNav") as HTMLSelectElement;
        const newId = parseInt(elSelect.value, 10);
        if (newId && newId !== props.productId) {
            window.location.href = elSelect.options[elSelect.selectedIndex].dataset.url;
        }
    }

    function hasOnlyOnePostPackage(): boolean {
        return (sailing.value.prePackages.length === 0 && sailing.value.addOnDayTours.length === 0 && sailing.value.postPackages.length === 1);
    }
    function hasOnlyOnePrePackage(): boolean {
        return (sailing.value.prePackages.length === 1 && sailing.value.addOnDayTours.length === 0 && sailing.value.postPackages.length === 0);
    }
    function hasOnlyOneShoreExcursion(): boolean {
        return (sailing.value.prePackages.length === 0 && sailing.value.addOnDayTours.length === 1 && sailing.value.postPackages.length === 0);
    }
    function hydratePrePostPackages(packages: AddOnPackage[]): TourEvent[] {
        const hydratedPackages: TourEvent[] = [];
        packages.forEach((pkg: AddOnPackage) => {
            const tourEventId = parseInt(pkg.packageMasterEntityID, 10);
            hydratedPackages.push({
                companyName: pkg.supplierName,
                description: sanitizeUserGeneratedContent(pkg.packageDescription),
                eventDate: pkg.packageDateFormatted,
                eventLength: pkg.packageLength,
                id: tourEventId,
                location: pkg.locationFormatted,
                name: pkg.packageName,
                thumbnailImageUrl: (pkg.imageLibraryItems && pkg.imageLibraryItems[0].url) ? generateMediaServerImageUrl(pkg.imageLibraryItems[0].url, { width: 230 }) : "https://virtuoso-prod.dotcms.cloud/images/photo-coming-soon-148x110.png",
                url: cobrandLink(`/packages/${pkg.packageMasterEntityID}/${slugify(pkg.packageName)}`),
                virtuosoHotel: (pkg.supportingPropertyName) ? pkg.supportingPropertyName : ""
            });
        });
        return hydratedPackages;
    }

    function loadOverview(): void {
        const sailingOverview = window.VIRTUOSO.sailingOverviewData;

        if (sailingOverview && sailingOverview.CruiseName) {
            loadSailing(); // Start the full load ASAP

            const sailingCruiseLength = (sailingOverview.CruiseLength).replace("days", "Days");
            const thisSailing: ProductDetailsSailing = {
                addOnDayTours: [],
                benefitsHostedGroups: [],
                benefitsHQGroups: [],
                benefitsExclusive: [],
                brandUrl: "",
                companyId: (sailingOverview.SupplierId) ? parseInt(sailingOverview.SupplierId, 10) : 0,
                companyName: sailingOverview.SupplierName || "",
                cruiseDepartureCode: "",
                cruiseId: sailingOverview.MasterCruiseId,
                cruiseMapUrl: "",
                cruiseName: `${sailingOverview.CruiseName} (${sailingCruiseLength.replace(" ", "&nbsp;")})`,
                cruisePorts: `${sailingOverview.DeparturePort} to ${sailingOverview.ArrivalPort}`,
                cruiseTypeByShip: "",
                featuredImageUrl: sailingOverview.FeaturedImage || "https://virtuoso-prod.dotcms.cloud/images/image-not-available-results-266x200.png",
                featuredVideoUrl: sailingOverview.FeaturedVideoUrl,
                id: props.productId,
                isCruiseTour: false,
                itinerary: [],
                postPackages: [],
                prePackages: [],
                promotions: [],
                sailings: [],
                shipId: 0,
                shipName: sailingOverview.ShipName,
                shipUrl: "",
                travelDates: `${sailingOverview.DepartureDate} to ${sailingOverview.ReturnDate}`,
                travelLength: sailingCruiseLength,
                whatIsIncluded: "",
                whatIsNotIncluded: ""
            };

            heartableUrl.value = `https://www.virtuoso.com/travel/luxury-cruises/cruises/${sailingOverview.MasterCruiseId}/${slugify(sailingOverview.CruiseName)}`;

            sailing.value = thisSailing;
            cruiseTitle.value = `${sailingOverview.SupplierName} - ${sailingOverview.CruiseName} (${(sailingOverview.CruiseLength)})`;

            topSplashData.value = {
                companyName: thisSailing.companyName,
                featuredImageCaption: thisSailing.featuredImageCaption,
                featuredImageUrl: thisSailing.featuredImageUrl,
                featuredVideoUrl: thisSailing.featuredVideoUrl,
                productName: thisSailing.cruiseName,
                productType: ProductType.CRUISES,
                wanderlistId: heartableUrl.value,
                wanderlistTitle: cruiseTitle.value
            };

            isOverviewReady.value = true;

            nextTick(() => {
                enableHearts(productDetailRef.value);
            });
        } else {
            redirectOnError();
        }
    }

    function loadSailing(): void {
        getProductDetails(ProductType.CRUISES, props.productId).then((resultSailing: ProductDetailsCruiseResponse) => {

            if (resultSailing && resultSailing.companyName && resultSailing.companyName) {

                const cruiseLength = (resultSailing.cruiseLength).replace("days", "Days");

                const thisSailing: ProductDetailsSailing = {
                    addOnDayTours: [],
                    benefitsHostedGroups: [],
                    benefitsHQGroups: [],
                    benefitsExclusive: [],
                    brandUrl: cobrandLink(`/travel/luxury-cruises/cruise-lines/${resultSailing.companyId}/${slugify(resultSailing.companyName)}`),
                    companyId: resultSailing.companyId || 0,
                    companyName: resultSailing.companyName || "",
                    cruiseDepartureCode: resultSailing.cruiseDepartureCode || "",
                    cruiseId: `${resultSailing.cruiseId}`,
                    cruiseMapUrl: resultSailing.cruiseLargeImagePath || "",
                    cruiseName: `${resultSailing.cruiseName} (${cruiseLength.replace(" ", "&nbsp;")})`,
                    cruisePorts: resultSailing.cruisePorts,
                    cruiseTypeByShip: resultSailing.cruiseTypeByShip,
                    featuredImageUrl: (sailing.value.featuredImageUrl) ? sailing.value.featuredImageUrl : "https://virtuoso-prod.dotcms.cloud/images/image-not-available-results-266x200.png", // fallback, replaced later
                    featuredVideoUrl: (sailing.value.featuredVideoUrl) ? sailing.value.featuredVideoUrl : "",
                    id: props.productId,
                    isCruiseTour: (resultSailing.cruiseType && resultSailing.cruiseType.toLowerCase() === "cruisetour") ? true : false,
                    itinerary: [],
                    postPackages: [],
                    prePackages: [],
                    promotions: [],
                    sailings: [],
                    shipId: resultSailing.shipId,
                    shipName: resultSailing.shipName || "",
                    shipUrl: cobrandLink(`/travel/luxury-cruises/ships/${resultSailing.shipId}/${slugify(resultSailing.shipName)}`),
                    travelDates: resultSailing.travelDates,
                    travelLength: cruiseLength,
                    whatIsIncluded: "",
                    whatIsNotIncluded: ""
                };

                // What's (Not) Included
                if (resultSailing.whatIsIncludedItems) {
                    if (resultSailing.whatIsIncludedItems.length > 1) {
                        let theItems = "";
                        resultSailing.whatIsIncludedItems.forEach((item: string) => {
                            theItems += `<li>${item}</li>`;
                        });
                        thisSailing.whatIsIncluded = `<ul>${theItems}</ul>`;
                    } else {
                        thisSailing.whatIsIncluded = sanitizeUserGeneratedContent(resultSailing.whatIsIncludedItems[0]);
                    }
                }

                if (resultSailing.whatIsNotIncludedItems) {
                    if (resultSailing.whatIsNotIncludedItems.length > 1) {
                        let theItems = "";
                        resultSailing.whatIsNotIncludedItems.forEach((item: string) => {
                            theItems += `<li>${item}</li>`;
                        });
                        thisSailing.whatIsNotIncluded = `<ul>${theItems}</ul>`;
                    } else {
                        thisSailing.whatIsNotIncluded = sanitizeUserGeneratedContent(resultSailing.whatIsNotIncludedItems[0]);
                    }
                }

                // Images -- first image is the featured image
                let galleryImages: GalleryItem[] = [];
                if (resultSailing.imageLibraryItems && resultSailing.imageLibraryItems.length) {
                    galleryImages = hydrateImageGallery(resultSailing.imageLibraryItems);
                    thisSailing.featuredImageUrl = galleryImages[0].image;
                    thisSailing.featuredImageCaption = galleryImages[0].description;
                }
                thisSailing.galleryImages = galleryImages;


                // Featured Video
                if (resultSailing.supplierVideos && resultSailing.supplierVideos.length) {
                    const featuredVideo = resultSailing.supplierVideos.find((video) => video.isFeaturedVideo);
                    if (featuredVideo) {
                        thisSailing.featuredVideoCaption = featuredVideo.title;
                        if (featuredVideo.webContentURL !== sailing.value.featuredVideoUrl) {
                            thisSailing.featuredVideoUrl = featuredVideo.webContentURL; // Don't "overwrite" the property if it is the same from the overview, worried about the video resetting
                        }
                    }
                }


                // Other sailings, if populated
                if (resultSailing.sailings && resultSailing.sailings.length && resultSailing.sailings.length > 1) {
                    resultSailing.sailings.forEach((sailing) => {
                        if (sailing.masterEntityId && sailing.travelDates && sailing.detailUrl) {
                            thisSailing.sailings.push({
                                id: sailing.masterEntityId,
                                travelDates: sailing.travelDates,
                                url: cobrandLink(translateToConsumerUrl(sailing.detailUrl))
                            });
                        }
                    });
                }


                // Itinerary
                if (resultSailing.itineraryPoints && resultSailing.itineraryPoints.length) {
                    let previousDay = 0;
                    let currentDay = 0;
                    resultSailing.itineraryPoints.forEach((it) => {
                        currentDay = it.dayOfCruise;
                        if (it.dayOfCruise !== previousDay) {
                            thisSailing.itinerary.push({
                                dateFormatted: it.segmentDate,
                                description: "",
                                itineraryDay: currentDay,
                                stops: []
                            });
                            previousDay = currentDay;
                        }
                        if (thisSailing.itinerary[currentDay - 1]) { // Sanity check, just in case
                            thisSailing.itinerary[currentDay - 1].stops.push({
                                isOnLand: it.isOnLand,
                                location: it.portName,
                                timeArrive: (it.timeStart && it.timeStart !== "00:00:00") ? it.timeStart : "",
                                timeDepart: (it.timeEnd && it.timeEnd !== "00:00:00") ? it.timeEnd : ""
                            });
                            if (it.description && it.description !== "") {
                                thisSailing.itinerary[currentDay - 1].description = sanitizeUserGeneratedContent(it.description);
                            }
                        }
                    });
                }


                // Benefits
                if (resultSailing.cruiseBenefits && resultSailing.cruiseBenefits.benefitPrograms && resultSailing.cruiseBenefits.benefitPrograms.length) {
                    resultSailing.cruiseBenefits.benefitPrograms.forEach((benefitGroup) => {
                        if (benefitGroup.name === "Exclusive Benefits") {
                            if (benefitGroup.benefitGroups && benefitGroup.benefitGroups.length) {
                                benefitGroup.benefitGroups.forEach((ben) => {
                                    if (ben.sortedBenefits && ben.sortedBenefits.length) {
                                        thisSailing.benefitsExclusive = populateBenefits(ben.sortedBenefits);
                                    }
                                });
                            }

                        } else if (benefitGroup.name === "HQ Group Benefits" && isNetworkUser()) {
                            if (benefitGroup.benefitGroups && benefitGroup.benefitGroups.length) {
                                benefitGroup.benefitGroups.forEach((ben) => {
                                    if (ben.sortedBenefits && ben.sortedBenefits.length) {
                                        thisSailing.benefitsHQGroups.push({
                                            benefits: populateBenefits(ben.sortedBenefits),
                                            visibilityCountries: (ben.visibilityCountries || "").replace(",", ", ")
                                        });
                                    }
                                });
                            }

                        } else if (benefitGroup.name === "Virtuoso Voyages Hosted Benefits") {
                            if (benefitGroup.benefitGroups && benefitGroup.benefitGroups.length) {
                                benefitGroup.benefitGroups.forEach((hbg) => {

                                    const hostedBenefits: CruiseBenefit[] = [];
                                    const forAllBenefits: CruiseBenefit[] = [];
                                    const hostList: string[] = [];
                                    const hostTitle = (hbg.hosts && hbg.hosts.length && hbg.hosts[0].hostedAssignedName !== "To Be Assigned") ? `Your&nbsp;Onboard&nbsp;Host${getPlural(hbg.hosts.length)}: ` : "Host ";

                                    if (hbg.sortedBenefits && hbg.sortedBenefits.length) {
                                        hbg.sortedBenefits.forEach((hb) => {
                                            const tempBenefit: CruiseBenefit =
                                            {
                                                activityLevel: hb.activityLevel || "",
                                                ageAppropriateness: hb.ageAppropriateness || "",
                                                availability: hb.displayedAvailability,
                                                benefitDate: hb.benefitDate,
                                                duration: hb.benefitDuration || "",
                                                capacity: hb.capacity,
                                                description: sanitizeUserGeneratedContent(hb.benefitDescription),
                                                experiences: (hb.activityType) ? hb.activityType.slice(0, -1).replaceAll("|", " &bull; ") : "",
                                                id: hb.benefitId,
                                                isCarAndDriver: hb.isCarAndDriver,
                                                isEvent: hb.isEvent,
                                                isForAllGuests: hb.isForAllGuests,
                                                isOption: hb.isOption,
                                                location: hb.benefitLocation,
                                                meals: hb.meals || "",
                                                notice: (hb.notice && !hb.isForAllGuests) ? hb.notice : "", // Only display the notice for hosted chosen benefits
                                                name: hb.benefitName,
                                                thumbnailImageUrl: hb.thumbnailImageUrl,
                                                waitlist: hb.displayedWaitlist || ""
                                            };

                                            if (hb.isForAllGuests) {
                                                forAllBenefits.push(tempBenefit);
                                            } else {
                                                hostedBenefits.push(tempBenefit);
                                            }

                                            hasBenefits.value = true;
                                        });
                                    }

                                    if (hbg.hosts && hbg.hosts.length) {
                                        hbg.hosts.forEach((host) => {
                                            if (host.hostedAssignedName) {
                                                const memberName = (host.memberName) ? `, ${host.memberName}` : "";
                                                hostList.push(host.hostedAssignedName + memberName);
                                            }
                                        });
                                    }

                                    thisSailing.benefitsHostedGroups.push({
                                        dates: (hbg.benefitGroupStartDate !== hbg.benefitGroupEndDate) ? `${hbg.benefitGroupStartDate} - ${hbg.benefitGroupEndDate}` : hbg.benefitGroupStartDate,
                                        forAllBenefits: forAllBenefits,
                                        hostedBenefits: hostedBenefits,
                                        hosts: hostTitle + hostList.join(", ")
                                    });

                                    if (hostedBenefits.length > 1) { // This controls whether we initialize the carousel or not
                                        hasMultipleHostedBenefits = true;
                                    }
                                });
                            }
                        }
                    });
                }


                // Add Ons -- Day tours and pre/post packages
                if (resultSailing.addOns && resultSailing.addOns.hasAddOns) {
                    // Pre Packages
                    if (resultSailing.addOns.prePackages && resultSailing.addOns.prePackages.length) {
                        thisSailing.prePackages = hydratePrePostPackages(resultSailing.addOns.prePackages);
                    }

                    // Post Packages
                    if (resultSailing.addOns.postPackages && resultSailing.addOns.postPackages.length) {
                        thisSailing.postPackages = hydratePrePostPackages(resultSailing.addOns.postPackages);
                    }

                    // Day Tours
                    if (resultSailing.addOns.addOnDayTours && resultSailing.addOns.addOnDayTours.length) {
                        const dayTours: AddOnDayTours[] = [];
                        const validDayTourSegments = resultSailing.addOns.addOnDayTours.filter((dayTour) => {
                            return dayTour.addOnDate && dayTour.addOnLocation && dayTour.dayTours;
                        });

                        validDayTourSegments.forEach((segment, segmentIndex: number) => {
                            if (segment.dayTours && segment.dayTours.length) {
                                dayTours.push({ // First add the date+location segment
                                    addOnDate: segment.addOnDate,
                                    addOnLocation: segment.addOnLocation,
                                    addOns: []
                                });

                                segment.dayTours.forEach((pkg) => { // Then loop through the tours for that segment and add them
                                    dayTours[segmentIndex].addOns.push({
                                        activityLevel: pkg.activityLevelList || "",
                                        ageAppropriateness: pkg.ageAppropriatenessList || "",
                                        companyName: pkg.supplierName,
                                        description: sanitizeUserGeneratedContent(pkg.dayTourDescription),
                                        eventDate: pkg.dayTourDateFormatted,
                                        eventLength: pkg.dayTourLength,
                                        experiences: (pkg.bulletedActivityTypes) ? pkg.bulletedActivityTypes : "",
                                        id: parseInt(pkg.dayTourMasterEntityID, 10), // cast to MEID string to number id
                                        location: pkg.locationFormatted,
                                        meals: pkg.mealsFormatted || "",
                                        name: pkg.dayTourName,
                                        thumbnailImageUrl: pkg.thumbnailImageUrl || "",
                                        url: cobrandLink(`/travel/luxury-tours/${pkg.dayTourMasterEntityID}/${slugify(pkg.dayTourName)}`),
                                        virtuosoHotel: ""
                                    });
                                });
                            }
                        });
                        thisSailing.addOnDayTours = dayTours;
                    }
                }


                // Promotions -- Virtuoso Exclusive Promotions added first
                if (resultSailing.promotions && resultSailing.promotions.length) {
                    const visiblePromotions: Promotion[] = [];
                    resultSailing.promotions.forEach((promo) => {
                        if (!promo.isAdvisorIncentive && !promo.incentiveTypeCode) {
                            visiblePromotions.push({
                                description: promo.description || "",
                                endDateMS: (promo.travelEndDate) ? new Date(promo.travelEndDate).getTime() : 0,
                                formattedTravelDates: promo.formattedTravelDates || "",
                                isExclusive: false,
                                name: promo.promotionName || "",
                                promotionId: promo.masterEntityId,
                                startDateMS: (promo.travelStartDate) ? new Date(promo.travelStartDate).getTime() : 0,
                                url: (promo.masterEntityId) ? cobrandLink(`/promotions/${promo.masterEntityId}/${slugify(promo.promotionName)}`) : ""
                            });
                        }
                    });

                    thisSailing.promotions = visiblePromotions;
                    topPromoName = (thisSailing.promotions.length) ? thisSailing.promotions[0].name : "";
                }


                sailing.value = thisSailing;
                topSplashData.value.promotion = thisSailing.promotions[0] ?? topSplashData.value.promotion; // Update for promos

                // Recommended Advisors
                recommendedAdvisorQuery.value = {
                    Id: thisSailing.id,
                    InterestType: thisSailing.cruiseTypeByShip,
                    ProductLocationCountry: "",
                    ProductPois: "",
                    ProductTypeName: "Cruise"
                } as RecommendedAdvisorQuery;


                isOverviewReady.value = true;
                isReady.value = true;
                // Onload Tab support
                showThenJumpToTab();
                nextTick(() => {

                    enableHearts(productDetailRef.value);


                    if (hasOnlyOnePostPackage()) {
                        autoExpandAndToggle("postpackages");
                    }

                    if (hasOnlyOnePrePackage()) {
                        autoExpandAndToggle("prepackages");
                    }

                    if (hasOnlyOneShoreExcursion()) {
                        autoExpandAndToggle("shore-excursion-0");
                    }
                });

            } else {
                redirectOnError();
            }
        }, () => redirectOnError());
    }
    
    function populateBenefits(rawBenefits: SortedBenefits[]): CruiseBenefit[] {

        let benefitGroup: CruiseBenefit[] = [];
        rawBenefits.forEach((eb) => {
            benefitGroup.push({
                description: sanitizeUserGeneratedContent(eb.benefitDescription),
                id: eb.benefitId,
                isCarAndDriver: eb.isCarAndDriver,
                isEvent: eb.isEvent,
                isForAllGuests: eb.isForAllGuests,
                isOption: eb.isOption,
                name: eb.benefitName,
                notice: "", // Never display the notice for Exclusive benefits
                thumbnailImageUrl: eb.thumbnailImageUrl
            });
            hasBenefits.value = true;
        });
        return benefitGroup;
    }

    function redirectOnError(): void {
        toastError("Error retrieving data");
        setTimeout(() => {
            location.href = cobrandLink(`/travel/luxury-cruises`);
        }, 3000);
    }

    function setRecommendedAdvisorIds(advisor1MEID?: number, advisor2MEID?: number): void {
        // get cruise index key in sessionStorage
        const getVirtuosoCruiseSearchIndexValue = sessionStorage.getItem(`VirtuosoCruiseSearchIndex_${props.productId}`);

        if (getVirtuosoCruiseSearchIndexValue) {
            // then delete cruise index key in sessionStorage
            sessionStorage.removeItem(`VirtuosoCruiseSearchIndex_${props.productId}`);
        }
        const cruise: ProductDetailsSailing = sailing.value;

        trackEvent("view_item", {
            ...((getVirtuosoCruiseSearchIndexValue) && { index: parseInt(getVirtuosoCruiseSearchIndexValue, 10) }), // should add index with value if session key existed
            item_id: `${props.productId}`,
            item_name: cruise.companyName,
            coupon: (cruise.promotions.length >= 1) ? "Promotion Available" : "",
            item_category: "Cruise",
            item_category2: `${cruise.shipName}`,
            item_category3: cruise.travelLength,
            item_category4: (advisor1MEID) ? `${advisor1MEID}` : "",
            item_category5: (advisor2MEID) ? `${advisor2MEID}` : ""
        });
    }

    function scrollToTabResponsive(tabName = ""): void {
        if (isMobileScreenWidth()) {
            return (tabName) ? document.getElementById(`tc-${tabName}`)?.scrollIntoView()
                : document.querySelector(".tab-content .-active")?.scrollIntoView({ block: "start" });
        }
        tabNavContainerRef.value.scrollIntoView();
    }

    function showTab(tabName: string, preventJump = false, fromTopLink = false): void {
        document.querySelectorAll(".tab-nav-container .-active, .tab-content .-active").forEach((el) => el.classList.remove("-active"));
        document.querySelectorAll(`#tab-${tabName}, #tc-${tabName}`).forEach((el) => el.classList.add("-active"));

        if (!preventJump) {
            scrollToTabResponsive(tabName);
        }

        if (tabName === "benefits") {
            if (hasMultipleHostedBenefits && !isBenefitsCarouselInitialized) {
                const carouselConfig: CarouselConfig = {
                    nextArrow: `<div class="slick-arrow-container -outset slick-arrow-next"><i class="icon-angle-right-ut"></i></div>`,
                    prevArrow: `<div class="slick-arrow-container -outset slick-arrow-prev"><i class="icon-angle-left-ut"></i></div>`
                };

                createCarousel($(`.hosted-benefits-carousel`), carouselConfig);
                isBenefitsCarouselInitialized = true;
            }
        }

        if (fromTopLink) {

            trackEvent("view_promotion", {
                item_id: `${sailing.value.promotions[0].promotionId}`,
                item_name: topPromoName,
                item_category: "Promotion",
                item_variant: "Cruise",
                item_category2: "Promo Visibility: All",
                affiliation: `${props.productId}`
            });
        }

    }

    function showThenJumpToTab(): void {

        const qsTabLabel = qsParams["tab"];

        nextTick(() => { // Tab Show then Scroll
            // Show        
            showTab("itinerary", true); // default first tab

            if (qsTabLabel && document.getElementById(`tc-${qsTabLabel}`)) { // check qsParam tab exists
                showTab(qsTabLabel, true);
            }
            // Show Promo Manual Tab
            if (qsParams.promotions === "1") { // If the manually added ?promotions=1 query string variable is present, scroll to the promotions section on load
                const qsPromoTabName = (sailing.value.promotions.length) ? "promotions" : "itinerary";
                showTab(qsPromoTabName, true);
            }

            // Default Scroll
            if (("tab" in qsParams) || ("promotions" in qsParams)) {
                scrollToTabResponsive();
            }
        });
    }

    function toggleAddOn(id: string): void {
        const btn = document.getElementById(`add-on-toggle-${id}`);
        const container = document.getElementById(`add-on-container-${id}`);


        btn.classList.toggle("icon-plus-circle-ut");
        btn.classList.toggle("icon-minus-circle-ut");
        toggleSlideWithFade(container, 500);
    }

    loadOverview(); // Immediately load the summary
</script>
