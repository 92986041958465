<template>
    <ul v-if="rooms.length" class="hotel-guest-rooms generic-cards -twocols -mobile-horizontal-scroll" aria-label="room list">
        <li v-for="(room, index) in rooms" :key="index" class="guest-room-card">
            <div class="bg-white p-2 shadow-sm h-100">
                <h4 class="fw-bold">{{ room.roomTypeName }}</h4>
                <div class="d-flex gap-3 text-16 flex-column flex-md-row">
                    <div class="-room-description" v-html="room.descriptionHtml"></div>
                    <div class="-room-features">
                        <ul aria-label="room feature list">
                            <li v-for="(feature, feature_idx) in getRoomFeatures(room.featureGroups)" :key="feature_idx" class="m-0 mw-100">
                                {{ feature }}
                            </li>
                        </ul>
                        <button class="btn btn-sm btn-tertiary mt-2" @click="(e) => handleViewMoreClick(e, index)">
                            View More
                        </button>
                    </div>
                </div>
            </div>
        </li>
    </ul>

    <ModalShell id="hotel-room-sidebar" :open="showRoomSidebar" modal-style="sidebar" @close="showRoomSidebar=false">
        <div class="d-flex flex-column h-100">
            <div ref="sidebar-room-details" class="p-5 text-16 overflow-auto">
                <h4 class="text-16 text-emphasis m-0">{{ hotelName }}</h4>
                <h4>{{ activeRoom.roomTypeName }}</h4>

                <div class="py-3" v-html="activeRoom.descriptionHtml"></div>

                <div>
                    <template v-for="(featureGroup, fgIndex) in activeRoom.featureGroups" :key="fgIndex">
                        <template v-if="featureGroup.features.length">
                            <div class="fw-bold mt-3">{{ featureGroup.groupName }}</div>
                            <ul class="room-feature-list d-flex flex-wrap">
                                <li v-for="(feature, fIndex) in featureGroup.features" :key="fIndex" class="mt-1">{{ feature }}</li>
                            </ul>
                        </template>
                    </template>
                </div>
            </div>
            <div v-if="rooms.length > 1" class="room-nav-buttons p-2 d-flex align-items-center w-100 mt-auto justify-content-between shadow-sm">
                <button class="btn btn-sm btn-tertiary d-flex align-items-center gap-1 flex-fill justify-content-center position-relative h-100" @click="(e) => handleViewMoreClick(e, prevRoom.index)">
                    <i class="icon-angle-left text-16 position-absolute start-0 ps-2"></i>
                    <span class="ps-1">{{ prevRoom.label }}</span>
                </button>
                <button class="btn btn-sm btn-tertiary d-flex align-items-center gap-1 flex-fill justify-content-center position-relative h-100" @click="(e) => handleViewMoreClick(e, nextRoom.index)">
                    <span class="pe-1">{{ nextRoom.label }}</span>
                    <i class="icon-angle-right text-16 position-absolute end-0 pe-2"></i>
                </button>
            </div>
        </div>
    </ModalShell>
</template>


<script setup lang="ts">
    import ModalShell from "components/shared/modal-shell.vue";
    import { GuestRoom, GuestRoomFeatureGroup } from "interfaces/responses/product-detail-responses";
    import { ProductType } from "interfaces/enums";
    import { trackEvent } from "services/analytics";
    import { capitalizeFirst } from "virtuoso-shared-web-ui";
    import { computed, PropType, ref, useTemplateRef } from "vue";


    const props = defineProps({
        hotelId: {
            type: Number,
            required: true
        },
        hotelName: {
            type: String,
            required: true
        },
        rooms: {
            type: Array as PropType<GuestRoom[]>,
            default: () => []
        }
    });

    const showRoomSidebar = ref(false);
    const activeItemIndex = ref(null);
    const sideRoomDetailsRef = useTemplateRef("sidebar-room-details");
    const totalRooms = props.rooms.length;
    
    const activeRoom = computed(() => props.rooms[activeItemIndex.value]);

    const nextRoom = computed(() => {
        const targetIndex = (activeItemIndex.value < totalRooms -1) ? activeItemIndex.value + 1 : 0;

        return {
            label: props.rooms[targetIndex].roomTypeName,
            index: targetIndex
        };
    });

    const prevRoom = computed(() => {
        const targetIndex = (activeItemIndex.value > 0) ? activeItemIndex.value - 1 : totalRooms - 1;

        return {
            label: props.rooms[targetIndex].roomTypeName,
            index: targetIndex
        };
    });


    function getRoomFeatures(groups: GuestRoomFeatureGroup[], max = 6) {
        return groups.map((featureGroup) => featureGroup.features).flat().slice(0, max);
    }

    function handleViewMoreClick(event: Event, roomArrayIndex: number) {
        (event.currentTarget as HTMLElement).blur();
        activeItemIndex.value = roomArrayIndex;
        showRoomSidebar.value = true;

        sideRoomDetailsRef.value?.scrollTo({top: 0, behavior: "smooth"});

        trackEvent("view_room_details", {
            item_id: props.hotelId.toString(),
            item_name: props.hotelName,
            item_category: capitalizeFirst(ProductType.HOTELS.slice(0,-1)),
            item_variant: activeRoom.value.roomTypeName
        });
    }

</script>
